import React, { ReactElement } from "react"
import Auth from "@aws-amplify/auth"
import { useEffect } from "react"
import DefaultLayout from "../templates/DefaultLayout"

export default function SignOut(): ReactElement {
  useEffect(() => {
    Auth.signOut()
    window.location.replace("/")
  }, [])
  return (
    <DefaultLayout>
      <div className="min-h-[80vh] flex items-center justify-center">
        Redirecting...
      </div>
    </DefaultLayout>
  )
}
